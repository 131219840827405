import React from "react"
import { Box, Text, Flex } from "rebass"
import { ConnectAccountButton } from "./connect-account-button"
import { DisconnectAccountButton } from "./disconnect-account-button"

const DEFAULT_NORTHONE_ACCOUNT_NAME = "NorthOne Business Banking"

interface IProps {
  businessId: string
  businessName: string
  account: {
    id: string
    accountNumber: string
  }
  xeroIntegration: {
    id: string
    status: string
    accounts: {
      accountId: string
      feedConnectionId: string
    }[]
    xeroOrganization: {
      id: string
      name: string
    }
  }
}

export const IntegrationSettingsBody: React.SFC<IProps> = ({
  xeroIntegration,
  account,
  businessId,
  businessName,
}) => {
  const connectedAccount = xeroIntegration.accounts.find(
    (xeroAccount) => xeroAccount.accountId === account.id,
  )
  const alreadyConnected = Boolean(connectedAccount)
  return (
    <Box>
      <Text fontSize="subtitle" color="grey" marginBottom="0.5rem">
        Xero Organization: {xeroIntegration.xeroOrganization.name}
      </Text>
      <Text fontSize="subtitle" color="grey" marginBottom="3rem">
        NorthOne Business: {businessName}
      </Text>

      <Text fontSize="subtitle" marginBottom="0.5rem">
        {DEFAULT_NORTHONE_ACCOUNT_NAME} - {account.accountNumber}
      </Text>
      <Text fontWeight="lighter" color={alreadyConnected ? "green" : "lightGrey"} fontSize="text">
        {alreadyConnected ? "Connected" : "Not Connected"}
      </Text>
      <Flex paddingTop="1rem" justifyContent={["center", "space-between"]}>
        {!alreadyConnected ? (
          <ConnectAccountButton
            integrationId={xeroIntegration.id}
            account={{
              accountId: account.id,
              accountNumber: account.accountNumber,
            }}
          />
        ) : (
          <DisconnectAccountButton
            integrationId={xeroIntegration.id}
            feedConnectionId={connectedAccount ? connectedAccount.feedConnectionId : ""}
          />
        )}
      </Flex>
    </Box>
  )
}
