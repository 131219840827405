import React from "react"
import { Box, Image } from "rebass"
import { theme } from "../theme"
import Check from "images/check.svg"

interface IProps {
  totalSteps: number
  currentStep: number
  section: number
  active: boolean
  color?: string
}

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

const createArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"
  const d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ")

  return d
}

const getEndAngle = (totalSteps: number, currentStep: number) => (currentStep / totalSteps) * 359.99

export const Progress: React.SFC<IProps> = ({
  totalSteps,
  currentStep,
  section,
  active,
  color,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "3.7rem",
        marginTop: "-1rem",
      }}
      paddingTop={["5rem", "5rem", "0rem", "0rem", "0rem"]}
    >
      <Box
        style={{
          width: 51,
          height: 51,
          borderRadius: 100,
          textAlign: "center",
          backgroundColor:
            active || section === 1 || (section === 2 && !active && color === "white")
              ? "white"
              : `${theme.colors.green}`,
          position: "absolute",
          boxShadow:
            active || section === 1 || (section === 2 && !active && color === "white")
              ? `0px 0px 10px 0px ${theme.colors.mediumGrey}`
              : "none",
        }}
      >
        <Box
          color={
            section === 1 || active || (section === 2 && !active && color === "white")
              ? `${theme.colors.green}`
              : "white"
          }
          style={{
            padding: "0.8rem",
          }}
          fontSize={theme.fontSizes[4]}
        >
          {(section === 1 && currentStep === totalSteps) ||
          (section === 1 && !active) ||
          (section === 2 && !active && color === "white") ||
          (active && currentStep === totalSteps) ? (
            <Image src={Check} alt="check" />
          ) : (
            section
          )}
        </Box>
      </Box>
      {active && currentStep !== totalSteps ? (
        <svg height={100} width={100} style={{ position: "absolute" }}>
          <path
            d={createArc(50, 50, 25, 0, getEndAngle(totalSteps, currentStep))}
            id="arc1"
            fill="none"
            stroke={theme.colors.green}
            strokeWidth="6"
          />
        </svg>
      ) : null}
    </Box>
  )
}
