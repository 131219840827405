import React from "react"
import { Box, Image } from "rebass"
import northone from "images/northone.svg"
import { theme } from "../theme"
import { LogoutButton } from "./logout-button.component"

interface IProps {
  header?: React.ReactNode
  sidePanel?: React.ReactNode
  body?: React.ReactNode
  hasLogoutComponent?: boolean
}

export const BaseLayout: React.SFC<IProps> = ({
  header,
  sidePanel,
  body,
  hasLogoutComponent = false,
}) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(14, 1fr)",
      gridTemplateRows: "repeat(12, 1fr)",
      height: "100vh",
      width: "100vw",
      overflow: "hidden",
    }}
  >
    <Box
      sx={{
        gridColumnStart: [2, 2, 2, 3, 3],
        gridColumnEnd: [14, 13, 10, 10, 10],
        gridRow: 1,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
      }}
    >
      <Image src={northone} />
      {hasLogoutComponent && <LogoutButton />}
    </Box>
    <Box
      sx={{
        gridColumnStart: [15, 14, 11, 11, 11],
        gridColumnEnd: 15,
        gridRowStart: 1,
        gridRowEnd: 13,
        borderTopLeftRadius: theme.borderRadius,
        borderBottomLeftRadius: theme.borderRadius,
      }}
      backgroundColor="blue"
    >
      {sidePanel}
    </Box>
    <Box
      sx={{
        gridColumnStart: [2, 2, 2, 3, 3],
        gridColumnEnd: [14, 13, 10, 10, 10],
        gridRowStart: [3, 4],
        gridRowEnd: 13,
      }}
    >
      {body}
    </Box>
  </Box>
)
