import React from "react"
import { Button, ErrorToast } from "shared"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

interface IProps {
  integrationId: string
  account: {
    accountId: string
    accountNumber: string
  }
}

export const CREATE_FEED_CONNECTION = gql`
  mutation xeroFeedConnectionCreate($integrationId: ID!, $account: XeroFeedConnectionAccountData!) {
    xeroFeedConnectionCreate(integrationId: $integrationId, account: $account) {
      code
      message
      success
      xeroIntegration {
        id
        accounts {
          accountId
          feedConnectionId
        }
      }
    }
  }
`

export const ConnectAccountButton: React.FC<IProps> = ({ integrationId, account }) => {
  const [createFeedConnection, { loading, error, data }] = useMutation(CREATE_FEED_CONNECTION, {
    variables: { integrationId, account },
  })
  const response = data ? data.xeroFeedConnectionCreate : null
  const hasCustomErrorMessage = response && !response.success && response.message

  return (
    <>
      <Button onClick={() => createFeedConnection()} type="primary" isLoading={loading}>
        {"Connect Account"}
      </Button>
      {(hasCustomErrorMessage || error) && (
        <ErrorToast type="custom" message={hasCustomErrorMessage && response.message} />
      )}
    </>
  )
}
