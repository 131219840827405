export const theme = {
  breakpoints: ["425px", "834px", "1112px", "1280px", "1440px"],
  borderRadius: "1.5rem",
  colors: {
    orange: "#FF8C0F",
    blue: "#009BFF",
    green: "#64CACB",
    black: "#27343D",
    lightGrey: "#C8C8C8",
    mediumGrey: "#777676",
    red: "#FF0000",
    white: "#FFFFFF",
    danger: "#FF5B5E",
  },
  fonts: {
    body: "Cerebri Sans",
    heading: "Cerebri Sans",
    monospace: "Cerebri Sans",
  },
  shadows: {
    tooltip: "4px 4px 15px rgba(0, 0, 0, 0.2)",
  },

  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],

  fontSizes: [12, 14, 16, 18, 24, 36],

  lineHeights: {
    title: 2.9,
    subtitle: 1.45,
    input: 1.5,
    header: 1.9,
    button: 1.15,
    text: 1,
  },
}

// aliases
// @ts-ignore
theme.fontSizes.note = theme.fontSizes[0]
// @ts-ignore
theme.fontSizes.button = theme.fontSizes[1]
// @ts-ignore
theme.fontSizes.text = theme.fontSizes[2]
// @ts-ignore
theme.fontSizes.subtitle = theme.fontSizes[3]
// @ts-ignore
theme.fontSizes.heading = theme.fontSizes[4]
// @ts-ignore
theme.fontSizes.title = theme.fontSizes[5]
