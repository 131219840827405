import React from "react"
import { Text } from "rebass"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import { RadioButton } from "./radio-button.component"

export const BUSINESSES_QUERY = gql`
  query businesses {
    me {
      id
      ownerBusinesses {
        id
        name
      }
    }
  }
`

interface IBusiness {
  id: string
  name: string
}

export const BusinessSelect: React.FC<{
  selectBusiness: (businessId: string) => void
  selectedBusinessId: string
}> = ({ selectBusiness, selectedBusinessId }) => {
  const { data, loading, error } = useQuery(BUSINESSES_QUERY)
  if (loading) {
    return (
      <Text fontSize="subtitle" color="grey">
        Loading...
      </Text>
    )
  }
  if (error) {
    return (
      <Text fontSize="subtitle" color="black">
        Error getting businesses
      </Text>
    )
  }
  if (data && data.me && data.me.ownerBusinesses) {
    if (!selectedBusinessId) {
      selectBusiness(data.me.ownerBusinesses[0].id)
    }
    return data.me.ownerBusinesses.map((business: IBusiness) => (
      <RadioButton
        isSelected={business.id === selectedBusinessId}
        key={business.id}
        label={business.name}
        onClick={() => selectBusiness(business.id)}
      />
    ))
  }
  return null
}
