import React from "react"
import { Box, Text, Heading, Image } from "rebass"

interface IProps {
  emoji?: string
  title: string
  subtitle?: string
  multilineSubtitle?: string[]
  imageSrc?: string
}

export const HeadingWithIcon: React.SFC<IProps> = ({
  emoji,
  title,
  subtitle,
  multilineSubtitle,
  children,
  imageSrc,
}) => (
  <Box>
    {emoji ? (
      <Text marginBottom="2rem" fontSize="title">
        {emoji}
      </Text>
    ) : null}
    {imageSrc ? <Image src={imageSrc} marginBottom="2rem" /> : null}
    <Heading marginBottom="1rem" fontSize={["heading", "heading", "title"]} color="black">
      {title}
    </Heading>
    {multilineSubtitle ? (
      multilineSubtitle.map((line, idx) => (
        <Text color="mediumGrey" fontSize="subtitle" marginTop="0.5rem">
          {line}
        </Text>
      ))
    ) : subtitle ? (
      <Text color="mediumGrey" fontSize="subtitle" lineHeight="2rem">
        {subtitle}
      </Text>
    ) : null}
    {children}
  </Box>
)
