import React from "react"
import { useHistory } from "react-router"
import { Box } from "rebass"

import { BaseScreen, BusinessSelect } from "shared"
import { auth, _localStorage } from "core"
import { RoutesEnum } from "app"
import xeroLogo from "../../images/xero-logo.svg"

interface ISelectBusinessScreen {
  businessId: string
  setBusinessId: (id: string) => void
  toNextScreen: () => void
}

export const SelectBusinessBody: React.FC<ISelectBusinessScreen> = ({
  businessId,
  setBusinessId,
  toNextScreen,
}) => {
  const history = useHistory()
  return (
    <BaseScreen
      imageSrc={xeroLogo}
      title="Which NorthOne business bank feed would you like to manage?"
      buttonDisabled={!businessId}
      buttonOnClick={toNextScreen}
      onBackClick={() => {
        _localStorage.removeAccessToken()
        auth.logout()
        history.push(RoutesEnum.ROOT)
      }}
    >
      <Box paddingTop="2rem">
        <BusinessSelect
          selectedBusinessId={businessId}
          selectBusiness={(id) => setBusinessId(id)}
        />
      </Box>
    </BaseScreen>
  )
}
