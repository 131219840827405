import { useHistory } from "react-router"
import React from "react"
import { Link } from "rebass"
import { RoutesEnum } from "app"
import { theme } from "../theme"
import { auth, _localStorage } from "core"

export const LogoutButton: React.FC<{}> = React.memo(() => {
  const history = useHistory()

  return (
    <Link
      sx={{
        color: theme.colors.black,
        textDecoration: "underline",
        cursor: "pointer",
        marginTop: 0,
      }}
      fontSize="text"
      color="mediumGrey"
      onClick={() => {
        _localStorage.removeAccessToken()
        auth.logout()
        history.push(RoutesEnum.ROOT)
      }}
    >
      {"Logout"}
    </Link>
  )
})
