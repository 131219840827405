import React, { useState } from "react"
import { Box, Text, Flex } from "rebass"
import { Radio } from "./radio.component"
import { Button } from "./button.component"

interface IProps {
  isSelected: boolean
  label: string
  onClick: () => void
}

export const RadioButton: React.FC<IProps> = ({ isSelected = true, label, onClick }) => {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Button
        onMouseLeave={() => setIsHovering(false)}
        onMouseEnter={() => setIsHovering(true)}
        noSimulatedSavingOnClick
        onClick={onClick}
        type={isSelected ? "blueOutline" : "secondary"}
        isRadio
      >
        <Flex width="100%" padding="0 1rem" justifyContent="space-between">
          <Flex alignItems="center">
            <Radio isHovering={isHovering} isSelected={isSelected} />
            <Text ml="0.5rem">{label}</Text>
          </Flex>
        </Flex>
      </Button>
    </Box>
  )
}
