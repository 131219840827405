import React, { ReactNode } from "react"
import { Box } from "rebass"
import { TButtonType } from "./button.component"
import { HeadingWithIcon } from "./heading-with-icon.component"
import { Footer } from "./footer.component"

export interface IBaseScreenProps {
  emoji?: string
  title: string
  subtitle?: string
  multilineSubtitle?: string[]
  buttonText?: string
  onBackClick?: () => void
  buttonOnClick?: () => void
  buttonDisabled?: boolean
  largeChildren?: boolean
  onLoginScreen?: boolean
  buttonSupertext?: string
  buttonSupertextAction?: () => void
  noBackButton?: boolean
  buttonType?: TButtonType
  noButton?: boolean
  noFooter?: boolean
  buttonIsLoading?: boolean
  imageSrc?: string
  buttonComponent?: ReactNode
  buttonWithEnter?: boolean
}

export const BaseScreen: React.SFC<IBaseScreenProps> = ({
  emoji,
  title,
  subtitle,
  multilineSubtitle,
  buttonText,
  onBackClick,
  children,
  buttonOnClick = () => null,
  buttonDisabled = false,
  onLoginScreen,
  buttonSupertext,
  buttonSupertextAction,
  noBackButton,
  buttonType,
  noButton,
  noFooter,
  buttonIsLoading,
  imageSrc,
  buttonComponent,
  buttonWithEnter,
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "repeat(5, 1fr)",
        height: "100%",
      }}
    >
      <Box
        sx={{
          gridRowStart: 1,
          gridRowEnd: 5,
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <HeadingWithIcon
          emoji={emoji}
          title={title}
          subtitle={subtitle}
          multilineSubtitle={multilineSubtitle}
          imageSrc={imageSrc}
        />
        {children}
      </Box>
      <Box
        sx={{
          gridRowStart: 5,
          gridRowEnd: 6,
          display: "flex",
          alignItems: ["center", "flex-start"],
        }}
      >
        {noFooter ? null : (
          <Footer
            buttonWithEnter={buttonWithEnter}
            buttonType={buttonType}
            buttonOnClick={buttonOnClick}
            buttonText={buttonText || "Continue"}
            onBackClick={onBackClick}
            buttonDisabled={buttonDisabled}
            onLoginScreen={onLoginScreen}
            buttonSupertext={buttonSupertext}
            buttonSupertextAction={buttonSupertextAction}
            noBackButton={noBackButton}
            noButton={noButton}
            buttonIsLoading={buttonIsLoading}
            buttonComponent={buttonComponent}
          />
        )}
      </Box>
    </Box>
  )
}
