import React, { useEffect, useState } from "react"
import { Redirect, Route, RouteProps } from "react-router"

import { auth, _localStorage } from "core"
import { RoutesEnum } from "app"
import { validateAccessToken } from "utils"

type IProtectedRoute = RouteProps & {
  redirectPath?: string
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({
  redirectPath = RoutesEnum.ROOT,
  ...props
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(_localStorage.getAccessToken())
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchToken = async () => {
      let token: string | null = accessToken

      if (!auth.validateAccessToken(token)) {
        token = await auth.getTokenFromSession(token)
      }

      setAccessToken(token)
      setIsLoading(false)
    }
    setIsLoading(true)
    fetchToken()
  }, [accessToken])

  if (isLoading) {
    return null
  }

  if (validateAccessToken(accessToken)) {
    return <Route {...props} />
  }

  return <Route component={() => <Redirect to={{ pathname: redirectPath }} />} />
}
