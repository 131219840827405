import React, { ReactNode } from "react"
import { Box, Image } from "rebass"

import secure from "images/secure.svg"
import { TButtonType, Button } from "./button.component"
import { BackButton } from "./back-button.component"

interface IProps {
  buttonText: string
  onBackClick?: () => void
  buttonOnClick: () => void
  buttonDisabled?: boolean
  buttonType?: TButtonType
  onLoginScreen?: boolean
  buttonSupertext?: string
  buttonSupertextAction?: () => void
  noBackButton?: boolean
  noButton?: boolean
  buttonIsLoading?: boolean
  buttonComponent?: ReactNode
  buttonWithEnter?: boolean
}

export const Footer: React.SFC<IProps> = ({
  buttonText,
  onBackClick,
  buttonOnClick,
  buttonDisabled,
  onLoginScreen,
  buttonSupertext,
  buttonSupertextAction,
  noBackButton,
  buttonType = "primary",
  noButton,
  buttonIsLoading,
  buttonComponent,
  buttonWithEnter = true,
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: ["center", "space-between"],
      width: "100%",
      height: "70%",
    }}
  >
    {onLoginScreen ? (
      <Image src={secure} />
    ) : noBackButton ? (
      <Box />
    ) : (
      <BackButton onBackClick={onBackClick} />
    )}
    {noButton && !buttonComponent ? null : buttonComponent ? (
      buttonComponent
    ) : (
      <Box sx={{ display: "flex" }}>
        <Button
          disabled={buttonDisabled}
          withEnter={buttonWithEnter}
          onClick={buttonOnClick}
          buttonSupertext={buttonSupertext}
          buttonSupertextAction={buttonSupertextAction}
          type={buttonType}
          isLoading={buttonIsLoading}
        >
          {buttonText}
        </Button>
      </Box>
    )}
  </Box>
)
