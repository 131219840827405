import React from "react"
import { BaseLayout, SidePanel, BaseScreen } from "shared"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import { _localStorage } from "core"
import xeroLogo from "../../images/xero-logo.svg"
import { Link } from "rebass"

import { IntegrationSettingsBody } from "./integration-settings-body"
import { useHistory } from "react-router"
import { RoutesEnum } from "app"

export const INTEGRATION_AND_BUSINESS_QUERY = gql`
  query xeroIntegration($businessId: ID!, $integrationId: ID!) {
    business(businessId: $businessId) {
      id
      name
      bankAccountInfo {
        id
        accountNumber
      }
    }
    xeroIntegration(integrationId: $integrationId) {
      id
      status
      accounts {
        accountId
        feedConnectionId
      }
      xeroOrganization {
        id
        name
        shortCode
      }
    }
  }
`

export const IntegrationSettings: React.SFC<{}> = () => {
  const history = useHistory()
  const { data, loading, error } = useQuery(INTEGRATION_AND_BUSINESS_QUERY, {
    variables: {
      businessId: _localStorage.getBusinessId(),
      integrationId: _localStorage.getIntegrationId(),
    },
  })

  const getTitle = () => {
    if (loading) {
      return "Loading..."
    }
    if (error || (data.business && !data.xeroIntegration)) {
      return "Oops, something went wrong"
    }
    if (data && data.xeroIntegration && data.business) {
      return "Manage Your Xero Bank Feed"
    }
    return ""
  }
  const shortCode =
    data && data.xeroIntegration && data.xeroIntegration.xeroOrganization
      ? data.xeroIntegration.xeroOrganization.shortCode
      : null

  return (
    <BaseLayout
      sidePanel={<SidePanel currentStep={3} totalSteps={0} section={3} />}
      body={
        <BaseScreen
          imageSrc={xeroLogo}
          title={getTitle()}
          onBackClick={() => history.push(RoutesEnum.INTEGRATION_REQUEST)}
          noButton={!shortCode}
          buttonComponent={
            shortCode && (
              <Link
                href={createDeeplink(shortCode)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
                fontSize="text"
                color="mediumGrey"
              >
                {"Back to Xero"}
              </Link>
            )
          }
        >
          {data && data.business && data.xeroIntegration && (
            <IntegrationSettingsBody
              account={data.business.bankAccountInfo}
              xeroIntegration={data.xeroIntegration}
              businessId={data.business.id}
              businessName={data.business.name}
            />
          )}
        </BaseScreen>
      }
      hasLogoutComponent={true}
    />
  )
}

const createDeeplink = (shortCode: string) =>
  `https://go.xero.com/organisationlogin/default.aspx?shortcode=${shortCode}&redirecturl=/Bank/BankAccounts.aspx`
