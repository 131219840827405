import React, { useState } from "react"
import styled from "@emotion/styled"
import {
  typography,
  color,
  space,
  layout,
  border,
  fontSize,
  TypographyProps,
  BorderProps,
  SpaceProps,
} from "styled-system"
import { theme } from "../theme"
import { Box, Image } from "rebass"
import ShowIcon from "images/show-icon.svg"
import HideIcon from "images/hide-icon.svg"
import { TValidationTypes, ValidationText } from "./validation-text.component"
import { SystemCssProperties } from "@styled-system/css"

interface IProps {
  onChange: (value: any) => void
  value: string | number
  placeholderText: string
  secureTextEntry?: boolean
  maxLength?: number
  valueIsValid?: () => void
  validationText?: string
  validationType?: TValidationTypes
  focus?: boolean
  disabled?: boolean
  small?: boolean
  valueExceeds?: boolean
  sx?: SystemCssProperties
}

export const ENTER_KEY_CODE = 13

const Input = styled.input<TypographyProps & BorderProps & SpaceProps>`
  ::-webkit-input-placeholder {
    color: ${theme.colors.lightGrey}
  }
  ${space}
  ${typography}
  ${color}
  ${layout}
  ${border}
  ${fontSize}
`

const INPUT_WIDTH = "27rem"

export const TextInput: React.FC<IProps> = ({
  onChange,
  value,
  placeholderText,
  secureTextEntry = false,
  maxLength,
  validationText,
  validationType = "error",
  focus,
  disabled = false,
  small,
  sx,
}) => {
  const [isHidden, setIsHidden] = useState(secureTextEntry)
  return (
    <Box sx={sx}>
      <Box
        sx={{
          borderWidth: 0,
          borderBottomWidth: 1,
          borderColor: "mediumGrey",
          borderStyle: "solid",
          display: "flex",
          justifyContent: "space-between",
        }}
        width={["100%", "100%", "100%", "100%", "30rem"]}
      >
        <Input
          type={secureTextEntry && isHidden ? "password" : undefined}
          fontSize="subtitle"
          autoFocus={focus}
          pb="0.6rem"
          onChange={(event) => {
            if (!maxLength || event.target.value.length <= maxLength) {
              onChange(event.target.value)
            }
          }}
          placeholder={placeholderText}
          color="mediumGrey"
          width={small ? "22rem" : INPUT_WIDTH}
          borderWidth={0}
          style={{
            outline: "none",
          }}
          value={value}
          disabled={disabled}
        />

        {secureTextEntry ? (
          <Image
            onClick={() => setIsHidden(!isHidden)}
            sx={{ height: "1.5rem" }}
            src={isHidden ? HideIcon : ShowIcon}
          />
        ) : null}
      </Box>
      {validationText ? (
        <ValidationText validationType={validationType} text={validationText} />
      ) : null}
    </Box>
  )
}
