import React from "react"
import { theme } from "../theme"

export const Radio: React.SFC<{ isSelected: boolean; isHovering: boolean }> = ({
  isSelected,
  isHovering,
}) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="9"
      cy="9"
      r="8.5"
      fill="white"
      stroke={isSelected || isHovering ? theme.colors.blue : theme.colors.mediumGrey}
    />
    {isSelected ? <circle cx="9" cy="9" r="4" fill="#009BFF" /> : null}
  </svg>
)
