import React, { useState } from "react"
import { BaseLayout, SidePanel, TErrorTypes, ErrorToast } from "shared"
import { SelectBusinessBody } from "./select-business"
import { SelectIntegrationBody } from "./select-integration"
import { _localStorage } from "core"

enum IntegrationRequestScreens {
  SELECT_BUSINESS = "SELECT_BUSINESS",
  CHOOSE_XERO_ORG = "CHOOSE_XERO_ORG",
}

export const IntegrationRequest: React.FC<{}> = () => {
  const [businessId, setBusinessId] = useState("")
  const [integrationId, setIntegrationId] = useState("")
  const [errorType, setErrorType] = useState<TErrorTypes | null>(null)

  const [screen, setScreen] = useState(IntegrationRequestScreens.SELECT_BUSINESS)
  return (
    <>
      <BaseLayout
        sidePanel={<SidePanel currentStep={1} totalSteps={0} section={2} />}
        body={
          screen === IntegrationRequestScreens.SELECT_BUSINESS ? (
            <SelectBusinessBody
              setBusinessId={(id) => {
                setBusinessId(id)
              }}
              businessId={businessId}
              toNextScreen={() => {
                _localStorage.setBusinessId(businessId)
                setScreen(IntegrationRequestScreens.CHOOSE_XERO_ORG)
              }}
            />
          ) : (
            <SelectIntegrationBody
              integrationId={integrationId}
              selectIntegration={setIntegrationId}
              businessId={businessId}
              setErrorType={setErrorType}
              onBackClick={() => {
                setScreen(IntegrationRequestScreens.SELECT_BUSINESS)
              }}
            />
          )
        }
        hasLogoutComponent={true}
      />
      {errorType ? <ErrorToast type={errorType} /> : null}
    </>
  )
}
