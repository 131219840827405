type LocalStorageKeys = "accessToken" | "businessId" | "setIntegrationId"

class LocalStorageService {
  public setAccessToken(token: string): void {
    this.setItem("accessToken", token)
  }

  public getAccessToken(): string | null {
    return this.getItem("accessToken")
  }

  public removeAccessToken(): void {
    this.removeItem("accessToken")
  }

  public setBusinessId(id: string): void {
    this.setItem("businessId", id)
  }

  public getBusinessId(): string | null {
    return this.getItem("businessId")
  }

  public setIntegrationId(id: string): void {
    this.setItem("setIntegrationId", id)
  }

  public getIntegrationId(): string | null {
    return this.getItem("setIntegrationId")
  }

  private setItem(key: LocalStorageKeys, value: string) {
    localStorage.setItem(key, value)
  }

  private getItem(key: LocalStorageKeys): string | null {
    return localStorage.getItem(key)
  }

  private removeItem(key: LocalStorageKeys) {
    localStorage.removeItem(key)
  }
}

export const _localStorage = new LocalStorageService()
