import { createBrowserHistory } from "history"
import { auth, _localStorage } from "core"

const generateQueryParamValueRegex = (paramKey: string) => {
  // https://stackoverflow.com/a/32336875
  // matches param value in a url
  return new RegExp(`${paramKey}=([^&#]*)`)
}

export const getUrlQueryParamValue = (url: string, paramKey: string) => {
  const queryParamValueRegex = generateQueryParamValueRegex(paramKey)
  try {
    const matches = url.match(queryParamValueRegex)
    const paramValue = matches ? matches[1] : ""
    return paramValue
  } catch (e) {
    console.log(e)
    throw new Error("Forbidden input error")
  }
}

export const getAccessTokenFromQueryString = () => {
  const { hash } = window.location
  const queryStringParts = hash.substr(1).split(new RegExp("[=&]"))
  const accessTokenIndex = queryStringParts.indexOf("access_token")
  const accessToken = queryStringParts[accessTokenIndex + 1]
  return accessToken
}

export const getQueryParamValue = (key: "oauth_verifier" | "org") =>
  decodeURIComponent(getUrlQueryParamValue(window.location.search, key))

export const history = createBrowserHistory()

export const validateAccessToken = (token: string | null): boolean => {
  if (!token) {
    return false
  }

  if (auth.validateAccessToken(token)) {
    return true
  }

  _localStorage.removeAccessToken()
  return false
}
