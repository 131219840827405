import React, { useEffect } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { Button, TErrorTypes } from "shared"
import { _localStorage, auth } from "core"
import { useHistory } from "react-router"
import { RoutesEnum } from "app"

export const INTEGRATION_REQUEST = gql`
  mutation xeroIntegrationRequest($businessId: ID!) {
    xeroIntegrationRequest(businessId: $businessId) {
      code
      message
      success
      authURL
    }
  }
`

interface IRequestIntegrationButtonProps {
  businessId: string
  disabled: boolean
  setErrorType: (errorType: TErrorTypes) => void
  hasOtherIntegrations: boolean
}

export const RequestIntegrationButton: React.FC<IRequestIntegrationButtonProps> = ({
  businessId,
  disabled,
  setErrorType,
  hasOtherIntegrations,
}) => {
  const history = useHistory()
  const [requestConnection, { data, loading, error }] = useMutation(INTEGRATION_REQUEST)
  if (data && data.xeroIntegrationRequest && data.xeroIntegrationRequest.authURL) {
    window.location.href = data.xeroIntegrationRequest.authURL
  }
  useEffect(() => {
    if (error) {
      const accessToken = _localStorage.getAccessToken()
      if (!accessToken || !auth.validateAccessToken(accessToken)) {
        _localStorage.setAccessToken("")
        setErrorType("unauthorized")
      } else {
        setErrorType("generic")
      }
    }
  }, [setErrorType, error])

  return (
    <Button
      withEnter
      buttonSupertext={
        hasOtherIntegrations ? (loading ? "Loading..." : "Add another organization") : undefined
      }
      buttonSupertextAction={() => requestConnection({ variables: { businessId } })}
      isLoading={loading && !hasOtherIntegrations}
      onClick={() => {
        if (hasOtherIntegrations) {
          history.push(RoutesEnum.INTEGRATION_SETTINGS)
        } else {
          requestConnection({ variables: { businessId } })
        }
      }}
      disabled={disabled}
    >
      {hasOtherIntegrations ? "Continue" : "Authorize"}
    </Button>
  )
}
