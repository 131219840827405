import React from "react"
import { Box, Text } from "rebass"
import { ValidationIcon } from "./validation-icon.component"
import { useHistory, useLocation } from "react-router"
import { RoutesEnum } from "app"

export interface IErrorToastProps {
  message: string
  onClick?: () => void
}

export type TErrorTypes = "unauthorized" | "generic" | "custom"

export const ErrorToast: React.SFC<{
  type: TErrorTypes
  onClick?: () => void
  message?: string
}> = ({ type, message = "Something went wrong!", onClick }) => {
  const history = useHistory()
  const location = useLocation()

  const errorProps: { [key in TErrorTypes]: IErrorToastProps } = {
    unauthorized: {
      message: "Your session has expired! Please click here to login again",
      onClick: () => history.push(RoutesEnum.ROOT),
    },
    generic: {
      message: "Something went wrong! Please try again.",
      onClick: () =>
        location.pathname !== RoutesEnum.INTEGRATION_REQUEST
          ? history.push(RoutesEnum.INTEGRATION_REQUEST)
          : undefined,
    },
    custom: {
      message,
      onClick,
    },
  }
  return (
    <Box
      onClick={errorProps[type].onClick}
      backgroundColor="danger"
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        height: "4vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        padding: "0 1rem",
        cursor: errorProps[type].onClick ? "pointer" : "auto",
      }}
    >
      <Box pr="0.5rem">
        <ValidationIcon color="white" />
      </Box>
      <Text color="white" fontSize="note">
        {errorProps[type].message}
      </Text>
    </Box>
  )
}
