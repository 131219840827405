import React from "react"
import { Router, Route, Switch } from "react-router-dom"
import { ThemeProvider } from "emotion-theming"

import { Login, IntegrationRequest, IntegrationCreate, IntegrationSettings } from "features"
import { GlobalStyle } from "global-style"
import { theme } from "./theme"
import "./fonts/stylesheet.css"
import { history } from "utils"
import { ProtectedRoute } from "shared"

export enum RoutesEnum {
  ROOT = "/",
  INTEGRATION_REQUEST = "/integration-request",
  INTEGRATION_CREATE = "/integration-create",
  INTEGRATION_SETTINGS = "/integration-settings",
}

export const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Router history={history}>
      <Switch>
        <Route path={RoutesEnum.ROOT} exact component={Login} />
        <ProtectedRoute
          redirectPath={RoutesEnum.ROOT}
          path={RoutesEnum.INTEGRATION_REQUEST}
          exact
          component={IntegrationRequest}
        />
        <ProtectedRoute
          redirectPath={RoutesEnum.ROOT}
          path={RoutesEnum.INTEGRATION_CREATE}
          exact
          component={IntegrationCreate}
        />
        <ProtectedRoute
          redirectPath={RoutesEnum.ROOT}
          path={RoutesEnum.INTEGRATION_SETTINGS}
          exact
          component={IntegrationSettings}
        />
      </Switch>
    </Router>
  </ThemeProvider>
)
