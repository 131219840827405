import React from "react"
import { Box, Text } from "rebass"
import { ValidationIcon } from "./validation-icon.component"

export type TValidationTypes = "error" | "info"

interface IProps {
  text: string
  validationType?: TValidationTypes
}

export const ValidationText: React.SFC<IProps> = ({ text, validationType = "error" }) => (
  <Box sx={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
    {validationType === "error" ? (
      <Box mr="0.25rem">
        <ValidationIcon type={validationType} />
      </Box>
    ) : null}
    <Text
      sx={{ verticalAlign: "center" }}
      pb="0.5px"
      fontSize="note"
      color={validationType === "error" ? "red" : "mediumGrey"}
    >
      {text}
    </Text>
  </Box>
)
