import React from "react"
import { Button, ErrorToast } from "shared"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

interface IProps {
  integrationId: string
  feedConnectionId: string
}

export const DELETE_FEED_CONNECTION = gql`
  mutation xeroFeedConnectionDelete($integrationId: ID!, $feedConnectionId: ID!) {
    xeroFeedConnectionDelete(integrationId: $integrationId, feedConnectionId: $feedConnectionId) {
      code
      message
      success
      xeroIntegration {
        id
        accounts {
          accountId
          feedConnectionId
        }
      }
    }
  }
`

export const DisconnectAccountButton: React.FC<IProps> = ({ integrationId, feedConnectionId }) => {
  const [deleteConnection, { loading, error, data }] = useMutation(DELETE_FEED_CONNECTION, {
    variables: { integrationId, feedConnectionId },
  })
  const response = data ? data.xeroFeedConnectionDelete : null
  const hasCustomErrorMessage = response && !response.success && response.message

  return (
    <>
      <Button onClick={() => deleteConnection()} type="primary" isLoading={loading}>
        {"Disconnect Account"}
      </Button>
      {(hasCustomErrorMessage || error) && (
        <ErrorToast type="custom" message={hasCustomErrorMessage && response.message} />
      )}
    </>
  )
}
