import React from "react"
import { BaseScreen, TErrorTypes } from "shared"
import xeroLogo from "../../images/xero-logo.svg"
import { Box, Text } from "rebass"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import { RadioButton } from "shared/radio-button.component"
import { ApolloError } from "apollo-client"
import { RequestIntegrationButton } from "./request-integration-button"
import { _localStorage } from "core"

export const INTEGRATIONS_QUERY = gql`
  query xeroIntegrations($businessId: ID!) {
    xeroIntegrations(businessId: $businessId) {
      id
      status
      xeroOrganization {
        id
        name
      }
    }
  }
`

interface IXeroIntegration {
  id: string
  status: "ACTIVE" | "INACTIVE"
  xeroOrganization: {
    id: string
    name: string
  }
}

interface ISelectIntegrationBodyProps {
  integrationId: string
  selectIntegration: (id: string) => void
  businessId: string
  setErrorType: (errorType: TErrorTypes) => void
  onBackClick: () => void
}

const getTitle = (data: any, loading: boolean, error?: ApolloError) => {
  if (loading) {
    return "Loading..."
  }
  if (error) {
    return "There was an error getting your integrations"
  }
  if (data) {
    return data.xeroIntegrations.length > 0
      ? "Choose a Xero organization"
      : "Authorize NorthOne to connect with Xero"
  }
  return ""
}

export const SelectIntegrationBody: React.FC<ISelectIntegrationBodyProps> = ({
  integrationId,
  selectIntegration,
  businessId,
  setErrorType,
  onBackClick,
}) => {
  const { data, loading, error } = useQuery(INTEGRATIONS_QUERY, { variables: { businessId } })
  const renderContents = () => {
    if (data && data.xeroIntegrations && data.xeroIntegrations[0]) {
      if (!integrationId) {
        _localStorage.setIntegrationId(data.xeroIntegrations[0].id)
        selectIntegration(data.xeroIntegrations[0].id)
      }
      return data.xeroIntegrations.length <= 0
        ? null
        : data.xeroIntegrations.map((integration: IXeroIntegration) => (
            <RadioButton
              key={integration.id}
              isSelected={integrationId === integration.id}
              onClick={() => {
                _localStorage.setIntegrationId(integration.id)
                selectIntegration(integration.id)
              }}
              label={integration.xeroOrganization.name}
            />
          ))
    }
    if (data && data.xeroIntegrations) {
      return (
        <>
          <Text fontSize="subtitle" color="grey" width={["100%", "100%", "100%", "100%", "80%"]}>
            Once connected, your NorthOne bank transactions will sync with your preferred Xero
            organization.
          </Text>
          <Text
            fontSize="subtitle"
            color="grey"
            mt="1.5rem"
            width={["100%", "100%", "100%", "100%", "80%"]}
          >
            Transactions will sync regularly to achieve daily accuracy with no duplication. You’ll
            be able to match and reconcile your bank transactions directly in Xero.
          </Text>
        </>
      )
    }
    return null
  }
  const hasOtherIntegrations = data && data.xeroIntegrations.length !== 0
  return (
    <BaseScreen
      imageSrc={xeroLogo}
      title={getTitle(data, loading, error)}
      noButton={Boolean(loading || error)}
      buttonComponent={
        Boolean(loading || error) ? (
          undefined
        ) : (
          <RequestIntegrationButton
            hasOtherIntegrations={hasOtherIntegrations}
            businessId={businessId}
            disabled={hasOtherIntegrations ? !businessId || !integrationId : !businessId}
            setErrorType={setErrorType}
          />
        )
      }
      onBackClick={onBackClick}
    >
      <Box paddingTop="2rem">{renderContents()}</Box>
    </BaseScreen>
  )
}
