import auth0 from "auth0-js"
import moment from "moment"
import jwtDecode from "jwt-decode"

import { _localStorage } from "core"

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string

const auth0credentials = {
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  responseType: "token id_token",
  audience: "northoneCoreApi",
}

class AuthService {
  public auth0: auth0.WebAuth

  constructor(credentials: auth0.AuthOptions) {
    this.auth0 = new auth0.WebAuth({
      ...credentials,
    })
  }

  public async auth0Login(credentials: { email: string; password: string }, redirectUri?: string) {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          ...credentials,
          redirectUri,
        },
        (err: any, result: any) => {
          console.log("err", err, "result", result)
          if (err) {
            reject(err)
          }
          resolve(result)
        },
      )
    })
  }

  public validateAccessToken(token: string | null): boolean {
    if (!token) {
      return false
    }

    try {
      const decodedToken = jwtDecode(token) as any
      const currentTimestamp = moment().unix()
      return decodedToken.exp > currentTimestamp
    } catch (err) {
      console.log(`token decode error: ${err.message || err}`)
      return false
    }
  }

  public async getTokenFromSession(token?: string | null): Promise<string | null> {
    if (!token) {
      return null
    }

    return new Promise((resolve) =>
      this.auth0.checkSession(
        { redirectUri: window.location.origin },
        (err, response: auth0.Auth0DecodedHash) => {
          if (err) {
            if (!err.code) {
              console.log(`Error performing auth0.checkSession`)
            }
            return resolve(null)
          }

          if (response && response.accessToken) {
            _localStorage.setAccessToken(response.accessToken)
            return resolve(response.accessToken)
          }

          resolve(null)
        },
      ),
    )
  }

  public logout(returnTo = window.location.origin): void {
    this.auth0.logout({
      clientID: AUTH0_CLIENT_ID,
      returnTo,
    })
  }
}

export const auth = new AuthService(auth0credentials)
