import React, { useState, useEffect, useCallback } from "react"
import { BaseLayout, BaseScreen, SidePanel } from "shared"
import xeroLogo from "../images/xero-logo.svg"
import { TextInput } from "shared/text-input.component"
import validator from "validator"
import { auth, _localStorage } from "core"
import { getAccessTokenFromQueryString } from "utils"
import { useHistory } from "react-router-dom"
import { RoutesEnum } from "app"

export const Login: React.FC<{}> = () => {
  const [checkingStoredToken, setCheckingStoredToken] = useState(true)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loginInProgress, setLoginInProgress] = useState(false)

  const [error, setError] = useState("")
  const history = useHistory()

  const checkAndHandleToken = useCallback(
    async (accessToken: string | null) => {
      if (accessToken && !auth.validateAccessToken(accessToken)) {
        accessToken = await auth.getTokenFromSession(accessToken)
      }

      if (accessToken && auth.validateAccessToken(accessToken)) {
        _localStorage.setAccessToken(accessToken)
        return history.push(RoutesEnum.INTEGRATION_REQUEST)
      }
      setCheckingStoredToken(false)
    },
    [history],
  )
  // check token in local storage or from querystring
  useEffect(() => {
    checkAndHandleToken(getAccessTokenFromQueryString() || _localStorage.getAccessToken())
  }, [checkAndHandleToken])

  const login = async () => {
    setLoginInProgress(true)
    try {
      const appUri = process.env.REACT_APP_XERO_WEB_URI
      await auth.auth0Login({ email, password }, appUri)
    } catch (e) {
      setError(e.description)
    }
    setLoginInProgress(false)
  }
  if (checkingStoredToken) {
    return null
  }
  return (
    <BaseLayout
      sidePanel={<SidePanel currentStep={1} totalSteps={0} section={1} />}
      body={
        <BaseScreen
          imageSrc={xeroLogo}
          subtitle="Enter your NorthOne login details to get started"
          title="Create and manage your Xero bank feed"
          buttonText="Login"
          buttonOnClick={login}
          buttonDisabled={!validator.isEmail(email) || !password}
          noBackButton
          buttonIsLoading={loginInProgress}
        >
          <TextInput
            placeholderText="Enter your email"
            onChange={(v) => {
              if (error) {
                setError("")
              }
              setEmail(v)
            }}
            value={email}
            focus={true}
            sx={{ marginTop: "4rem" }}
          />
          <TextInput
            placeholderText="Enter your password"
            onChange={(v) => {
              if (error) {
                setError("")
              }
              setPassword(v)
            }}
            value={password}
            secureTextEntry
            focus={false}
            validationText={error}
            sx={{ marginTop: "2.5rem" }}
          />
        </BaseScreen>
      }
    />
  )
}
