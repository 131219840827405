import React, { useState, useEffect } from "react"
import { Button as RButton, Box, Text, ButtonProps } from "rebass"
import { theme } from "../theme"
import { INPUT_TAG_NAME } from "./back-button.component"

export type TButtonType = "primary" | "secondary" | "blueOutline" | "white" | "principal"
interface IProps extends ButtonProps {
  disabled?: boolean
  type?: TButtonType
  withEnter?: boolean
  onClick: () => void
  isCircle?: boolean
  isRadio?: boolean
  noSimulatedSavingOnClick?: boolean
  buttonSupertext?: string
  buttonSupertextAction?: () => void
  isLoading?: boolean
}

const buttonColors = {
  disabled: {
    borderColor: "mediumGrey",
    color: "mediumGrey",
    backgroundColor: "transparent",
    ":hover": {
      cursor: "default",
    },
  },
  primary: {
    borderColor: "blue",
    color: "white",
    backgroundColor: "blue",
    ":hover": {
      cursor: "pointer",
    },
  },
  white: {
    borderColor: "white",
    color: "blue",
    backgroundColor: "white",
    ":hover": {
      cursor: "pointer",
    },
  },
  secondary: {
    borderColor: "mediumGrey",
    color: "mediumGrey",
    backgroundColor: "transparent",
    ":hover": {
      borderColor: "blue",
      color: "blue",
      cursor: "pointer",
    },
  },
  blueOutline: {
    borderColor: "blue",
    color: "blue",
    backgroundColor: "transparent",
    ":hover": {
      cursor: "pointer",
    },
  },
  principal: {
    borderColor: "orange",
    color: "white",
    backgroundColor: "orange",
    ":hover": {
      cursor: "pointer",
    },
  },
}

export const SIMULATED_SAVING_MS = 300
const ENTER_KEY_CODE = 13
const RIGHT_ARROW_KEY_CODE = 39

const getButtonColors = (type: TButtonType, disabled: boolean) => {
  if (disabled) {
    return buttonColors.disabled
  }
  return buttonColors[type]
}

export const Button: React.FC<IProps> = (props) => {
  const {
    disabled = false,
    children,
    type = "primary",
    withEnter = false,
    isCircle = false,
    isRadio = false,
    onClick,
    noSimulatedSavingOnClick = true,
    buttonSupertext,
    buttonSupertextAction,
    isLoading = false,
  } = props
  const [simulatedIsSaving, setSimulatedOnSaving] = useState(false)
  const onSubmit = () => {
    if (!disabled && !simulatedIsSaving && !isLoading) {
      if (noSimulatedSavingOnClick) {
        onClick()
      } else {
        setSimulatedOnSaving(true)
        setTimeout(() => {
          onClick()
          setSimulatedOnSaving(false)
        }, SIMULATED_SAVING_MS)
      }
    }
  }
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const target = event.target as Element

      if (type === "primary") {
        if (
          !disabled &&
          !simulatedIsSaving &&
          !isLoading &&
          (event.keyCode === ENTER_KEY_CODE ||
            (event.keyCode === RIGHT_ARROW_KEY_CODE && target.tagName !== INPUT_TAG_NAME))
        ) {
          onSubmit()
        }
      }
    }
    document.addEventListener("keydown", onKeyDown)
    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
  })

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {buttonSupertext ? (
        <Text
          style={{
            position: "absolute",
            ...(buttonSupertextAction ? { textDecoration: "underline", cursor: "pointer" } : {}),
          }}
          mt="-3rem"
          fontSize="text"
          color="mediumGrey"
          onClick={buttonSupertextAction}
        >
          {buttonSupertext}
        </Text>
      ) : null}
      <RButton
        sx={{
          borderWidth: 1.3,
          borderStyle: "solid",
          borderRadius: theme.borderRadius,
          outline: "none",
          fontSize: "button",
          height: "3rem",
          width: isCircle ? "3rem" : "18rem",
          ...(isRadio
            ? { width: "28rem", marginRight: "1rem", marginBottom: "0.5rem", marginTop: "0.5rem" }
            : null),
          padding: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...getButtonColors(type, disabled),
        }}
        {...props}
        onClick={onSubmit}
      >
        {simulatedIsSaving || isLoading ? "Saving" : children}
      </RButton>
      {withEnter && !disabled ? (
        <Text
          style={{ position: "absolute" }}
          mt="2.5rem"
          fontSize="note"
          color={type === "white" ? "white" : "lightGrey"}
        >
          or press <span style={{ fontWeight: 600 }}>ENTER</span>
        </Text>
      ) : null}
    </Box>
  )
}
