import React from "react"
import { Box, Heading, Text } from "rebass"
import { Progress } from "./progress-circle.component"

interface IProps {
  heading: string
  color?: "white" | "green"
  text?: string
  section: number
  currentStep: number
  totalSteps: number
  active: boolean
}

export const HeadingWithProgress: React.SFC<IProps> = ({
  heading,
  color = "white",
  text,
  section,
  currentStep,
  totalSteps,
  active,
}) => (
  <Box style={{ marginBottom: "2rem" }}>
    <Box style={{ display: "flex", flexDirection: "row" }}>
      <Progress
        totalSteps={totalSteps}
        currentStep={currentStep}
        section={section}
        active={active}
        color={color}
      />
      <Heading
        style={{ marginBottom: "1rem" }}
        display={["none", "none", "block"]}
        fontSize="heading"
        color={color}
      >
        {heading}
      </Heading>
    </Box>
    <Box style={{ flexDirection: "column" }}>
      <Text
        display={["none", "none", "block"]}
        color={color}
        fontSize="text"
        lineHeight="1.5"
        marginLeft="3.8rem"
      >
        {text}
      </Text>
    </Box>
  </Box>
)
