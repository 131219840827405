import React from "react"
import { theme } from "../theme"

interface IProps {
  type?: "error" | "info"
  color?: string
}

export const ValidationIcon: React.SFC<IProps> = ({ type = "error", color }) => {
  const typeColor = type === "error" ? theme.colors.red : theme.colors.lightGrey
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85787 16.5 9 16.5Z"
          stroke={color || typeColor}
          strokeWidth="2"
          strokeLinecap="square"
        />
        <path d="M9 6L9 9" stroke={color || typeColor} strokeWidth="2" strokeLinecap="square" />
        <circle cx="9" cy="12" r="1" fill={color || typeColor} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
