import React from "react"
import { Box } from "rebass"
import { HeadingWithProgress } from "./heading-with-progress.component"

interface IProps {
  section: number
  currentStep: number
  totalSteps: number
}

const sectionText = [
  {
    heading: "Log in to NorthOne",
    text:
      "Enter your username and password to log in to your account. You’ll be asked to give Xero permission to access your bank feed.",
  },
  {
    heading: "Authorize NorthOne",
    text:
      "Once logged in and have selected the business you’d like to connect to Xero, you’ll be asked to give NorthOne permission to create the bank feed connection.",
  },
  {
    heading: "Bank Feed Connection",
  },
]

export const SidePanel: React.SFC<IProps> = ({
  section: currentSection,
  currentStep,
  totalSteps,
}) => {
  return (
    <Box
      sx={{
        display: ["none", "grid"],
        gridTemplateColumns: "repeat(8, 1fr)",
        gridTemplateRows: "repeat(7, 1fr)",
        height: "100vh",
      }}
      style={{ position: "relative" }}
    >
      <Box
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: [7, 7, 7, 8, 8],
          gridRowStart: 3,
          gridRowEnd: 6,
        }}
      >
        {[1, 2, 3].map((sectionNumber) => (
          <HeadingWithProgress
            key={sectionNumber}
            heading={sectionText[sectionNumber - 1].heading}
            text={currentSection === sectionNumber ? sectionText[sectionNumber - 1].text : ""}
            color={currentSection >= sectionNumber ? "white" : "green"}
            section={sectionNumber}
            currentStep={currentStep}
            totalSteps={totalSteps}
            active={currentSection === sectionNumber}
          />
        ))}
      </Box>
    </Box>
  )
}
