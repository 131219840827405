import React, { useEffect, useState } from "react"
import { BaseLayout, SidePanel, BaseScreen, TErrorTypes, ErrorToast } from "shared"
import { getQueryParamValue } from "utils"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { _localStorage, auth } from "core"
import { useHistory } from "react-router"
import { RoutesEnum } from "app"
import xeroLogo from "../../images/xero-logo.svg"

export const INTEGRATION_CREATE = gql`
  mutation xeroIntegrationCreate(
    $oauthVerifier: String!
    $xeroOrganizationId: ID!
    $businessId: ID!
  ) {
    xeroIntegrationCreate(
      oauthVerifier: $oauthVerifier
      xeroOrganizationId: $xeroOrganizationId
      businessId: $businessId
    ) {
      code
      message
      success
      xeroIntegration {
        id
        xeroOrganization {
          id
          name
        }
      }
    }
  }
`

export const IntegrationCreate: React.FC<{}> = () => {
  const [createConnection, { data, error }] = useMutation(INTEGRATION_CREATE)
  const [errorType, setErrorType] = useState<TErrorTypes | null>(null)
  const history = useHistory()

  useEffect(() => {
    createConnection({
      variables: {
        oauthVerifier: getQueryParamValue("oauth_verifier"),
        businessId: _localStorage.getBusinessId(),
        xeroOrganizationId: getQueryParamValue("org"),
      },
    })
  }, [createConnection])
  if (data && data.xeroIntegrationCreate && data.xeroIntegrationCreate.success) {
    _localStorage.setIntegrationId(data.xeroIntegrationCreate.xeroIntegration.id)
    history.push(RoutesEnum.INTEGRATION_SETTINGS)
  }
  useEffect(() => {
    if (error) {
      const accessToken = _localStorage.getAccessToken()
      if (!accessToken || !auth.validateAccessToken(accessToken)) {
        _localStorage.removeAccessToken()
        setErrorType("unauthorized")
      } else {
        setErrorType("generic")
      }
    }
  }, [error])
  return (
    <>
      <BaseLayout
        sidePanel={<SidePanel currentStep={1} totalSteps={0} section={3} />}
        body={
          <BaseScreen
            imageSrc={xeroLogo}
            buttonOnClick={() => null}
            noFooter
            title="Connecting to Xero..."
            noBackButton
          />
        }
        hasLogoutComponent={true}
      />
      {errorType ? <ErrorToast type={errorType} /> : null}
    </>
  )
}
