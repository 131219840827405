import React from "react"
import ReactDOM from "react-dom"
import { ApolloProvider } from "@apollo/react-hooks"
import { ApolloLink, concat } from "apollo-link"
import { onError } from "apollo-link-error"
import { HttpLink } from "apollo-link-http"
import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { osName, osVersion, mobileModel, browserName, browserVersion } from "react-device-detect"
import { setContext } from "apollo-link-context"

import { _localStorage, traceId, auth } from "core"
import { history, validateAccessToken } from "utils"
import { App, RoutesEnum } from "./app"
import * as serviceWorker from "./serviceWorker"
import "./normalize.css"
import pkg from "../package.json"

const uri = process.env.REACT_APP_GQL_URI

const CLIENT_NAME = "Xero Client"
const NOT_REQUIRE_AUTH_GQL_OPERATIONS: string[] = []

const tokenMiddleware = setContext(async () => {
  let accessToken = _localStorage.getAccessToken()

  if (!auth.validateAccessToken(accessToken)) {
    accessToken = await auth.getTokenFromSession(accessToken)
  }

  return { accessToken }
})

const authLink = new ApolloLink((operation, forward) => {
  const token = operation.getContext().accessToken

  if (!NOT_REQUIRE_AUTH_GQL_OPERATIONS.includes(operation.operationName)) {
    if (!validateAccessToken(token)) {
      console.log("invalid token")
      auth.logout()
      history.push(RoutesEnum.ROOT)
      return null
    }
  }

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      client: CLIENT_NAME,
      os_name: osName,
      os_version: `${osName} ${osVersion}`,
      ...(mobileModel !== "none" && { device_model: mobileModel }),
      browser: `${browserName} ${browserVersion}`,
      trace_id: traceId,
    },
  })

  return forward ? forward(operation) : null
})

const authMiddleware = ApolloLink.from([tokenMiddleware, authLink])

const httpLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      )
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  }),
  new HttpLink({
    credentials: "same-origin",
    fetch,
    uri,
  }),
])

const cache = new InMemoryCache()
const link = concat(authMiddleware, httpLink)
const client = new ApolloClient({
  link,
  cache,
  name: CLIENT_NAME,
  version: pkg.version,
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
